<template>
  <main class="main">
    <div class="main_wrap">
      <h1>빠르고 편리한 IT 프로젝트의 시작 <b>블루버튼</b></h1>
      <h2>
        비즈니스 전반에 걸친 최고의 역량을 갖춘<br />
        개발 파트너사를 찾는다면
      </h2>
      <p>
        시장검증, 기획, 개발, 디자인까지 전부 내재화된 인력으로,<br />단순한
        아웃소싱이 아닌 같이 프로덕트를 만들어 나가는 당신의 파트너
        <b>블루버튼</b> 입니다!
      </p>
      <button
        class="main_btn"
        @click.prevent.stop="
          goLink('https://xbv6jkqkvro.typeform.com/to/z1QqGU5B')
        "
      >
        멋진 프로젝트 만들기
        <img class="main_btn_icon" src="@/assets/images/bluebutton_logo.png" />
      </button>
    </div>
    <div class="ani-area">
      <ul class="circles">
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
      </ul>
    </div>
  </main>
</template>

<script>
import client from "api-client";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      url: "/api/mailing",
      email: "",
      waitRequest: true,
    };
  },
  computed: {
    ...mapGetters(["isAuthenticated"]),
  },
  methods: {
    goLink(link = "") {
      window.fbq("track", "CompleteRegistration");
      window.open(link);
    },
    async formSubmit(evt) {
      evt.preventDefault();
      let isValidation = await this.validation();
      if (isValidation) {
        this.create();
      }
    },
    async validation() {
      const refEmail = await this.$refs.refEmail.validate();
      if (!refEmail.valid) {
        alert(refEmail.errors[0]);
        this.$refs.refEmailInput.focus();
        return false;
      }
      return true;
    },
    async create() {
      let params = {
        email: this.email,
      };
      this.waitRequest = true;
      client.mailingEdit(params).then(
        () => {
          this.waitRequest = false;
          alert("이메일 등록이 완료되었습니다.");
          this.email = "";
        },
        (error) => {
          this.waitRequest = false;
          alert(error);
        }
      );
    },
  },
};
</script>
